import { MatDialog } from '@angular/material';
import { AuthService } from './../../auth/auth.service';
import { DataService } from './../../data.service';
import { Router } from '@angular/router';
import { Solicitante } from './../../Classes/app-models';
import { AppComponent } from './../../app.component';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { CommonConstants } from '../../shared/commonconstants';
import { FullDialogComponent } from '../../PageComponents/full-dialog/full-dialog.component';

enum RazonRechazo { NoBancarizado = 0, SuperDeudor = 1, ClienteMentiroso = 2, ReglaDeNegocio = 3, ClienteYaExiste = 4, Otra = 5 }

@Component({
  selector: 'app-evaluar-solicitud',
  templateUrl: './evaluar-solicitud.component.html',
  styleUrls: ['./evaluar-solicitud.component.css'],

})
@Injectable()
export class EvaluarSolicitudComponent implements OnInit {

  ngOnInit(): void {

    console.log(this.data.Prestamo.CantidadDeCuotas);
    console.log(this.data.Prestamo.CantidadAPagar);

    // this.data.Documento = "36644489";
    // this.data.Sexo = "Masculino";
    // this.data.Ingreso = 16666;
    // this.data.DescuentoDicho = 0;
    this.data.Prestamo.CantidadDeCuotas = this.data ? this.data.Prestamo.CantidadDeCuotas : 12;
    this.data.Prestamo.CantidadPedida = this.data ? this.data.Prestamo.CantidadPedida : 20000;

    // this.data.Prestamo.Cuota = 1319;
    // this.data.Prestamo.TasaDeInteres = 0.2319;

    this.data.CondicionesDePrestamo = {};
    //this.data.CondicionesDePrestamo.CuotaMaxima = 5635;
    //this.data.CondicionesDePrestamo.PrestamoMaximo = 30000;
    //this.data.CondicionesDePrestamo.TasasDeInteres = [0.2178, 0.2178, 0.2178, 0.2178, 0.2178, 0.2178, 0.1936, 0.1936, 0.1936, 0.1936, 0.1936, 0.1936];

    this.CPText = this.CP = this.data.Prestamo.CantidadPedida;
    this.CCText = this.CC = this.data.Prestamo.CantidadDeCuotas;
    
    this.showBar = false;

    this.forma = new FormGroup({
      Documento: new FormControl('', [Validators.required, Validators.pattern('^[1-9]{1}[0-9]{6,7}$')]),
      Ingreso: new FormControl('', [Validators.required]),
      Sexo: new FormControl('', [Validators.required]),
      DescuentoDicho: new FormControl('', [Validators.required]),
      CantidadPedida: new FormControl('', [Validators.required]),
      CantidadDeCuotas: new FormControl('', [Validators.required]),
      TipoClienteDicho: new FormControl('', [Validators.required]),
      IdentificadoPor: new FormControl('', [Validators.required]),
      Terminos: new FormControl('', [Validators.requiredTrue]),
      recaptchaReactive: new FormControl(null, Validators.required)
    })
  }

  @ViewChild(ReCaptchaComponent) captcha: ReCaptchaComponent;

  CPText: any;
  CCText: any;
  CC: number;
  CP: number;
  response: any = {};
  param: any = {};
  termsClicked: boolean = false;
  captchaClicked: boolean = false;
  terms: boolean = false;
  disabled: boolean = false;
  showBar: boolean = true;
  forma: FormGroup;
  sexos = [
    'Masculino',
    'Femenino',
  ];
  
  constructor(private auth: AuthService, private http: Http, public appComponent: AppComponent, private dialog: MatDialog, public dataService: DataService, public router: Router, private fb: FormBuilder) {
  }

  get data(): Solicitante {
    if (!this.dataService.solicitanteData) {
      this.dataService.solicitanteData = new Solicitante();
    }
    return this.dataService.solicitanteData;
  }


  public evaluarPrestamo() {
    this.termsClicked = true;
    this.captchaClicked = true;

    this.data.Identificacion = {};
    this.data.Identificacion[this.data.IdentificadoPor] = this.data.Documento;

    if (this.forma.valid) {
      this.showBar = true;
      this.disabled = true;
      let body = JSON.stringify(this.data);
      let options = new RequestOptions({ headers: new Headers({ 'Content-Type': 'application/json' }) });
      let url = CommonConstants.WebAPIEndpoints.EvaluarSolicitud;

      console.log(body);

      let req = this.http.post(url,
        body, options)
        .subscribe(
          (res: any) => {
            this.response = JSON.parse(res._body);
            this.appComponent.Jwt = this.response.Jwt;
            console.log(this.response);

            //Este fix es una mugre pero es culpa de todos los demas excepto de mi
            if (this.response && this.response.CondicionesDePrestamo && this.response.CondicionesDePrestamo.PrestamoMaximo == 0) {
              this.errorMessage('Notificación', 'Hubo un error. Por favor complete el formulario de contacto.', true);
            }
            else if (!this.response.RazonRechazo && this.response.RazonRechazo != 0) {
              Object.keys(this.response).forEach(key => this.data[key] = this.response[key]);
              console.log(this.data);
              this.router.navigate(['/datos-personales']);
            }
            else {
              switch (this.response.RazonRechazo) {
                case RazonRechazo.NoBancarizado:
                  this.errorMessage('Notificación', 'Necesitamos más información. Por favor complete el formulario de contacto.', true);
                  break;
                case RazonRechazo.ClienteMentiroso:
                  this.errorMessage('Error en su información', 'Por favor revise los campos ingresados.', false);
                  break;
                case RazonRechazo.SuperDeudor:
                  this.errorMessage('Notificación', 'Lo sentimos, en este momento no tenemos una oferta para Ud.', false);
                  break;
                case RazonRechazo.ReglaDeNegocio:
                  this.errorMessage('Notificación', 'Su solicitud está en proceso de revisión, complete el formulario y a la brevedad nos contactaremos con Ud.', true);
                  break;
              }
            }
          },
          err => {
            this.showBar = false;
            this.disabled = false;
            console.log(err);
            console.log('Error occured');

            // Cartel de error
            this.errorMessage('Hubo un error', 'Por favor llene el formulario de contacto y nos estaremos comunicando con usted.', true)

          }, () => this.showBar = false
        );
    }
  }
  
  errorMessage(title: string, mensaje: string, redirect: boolean) {
    let dialogRef = this.dialog.open(FullDialogComponent, {
      data: { title: title, mensaje: mensaje },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.captcha.reset();
      if (redirect) {
        this.router.navigate(['/contacto']);
      }
      console.log(`Dialog closed: ${result}`);
    });
  }
  // Metodo de google para guardar el captcharesponse y luego enviarselo al servidor para validar
  handleCorrectCaptcha(captchaResponse) {
    try {
      this.data.CaptchaToken = captchaResponse;
      this.captchaClicked = true;
    } catch (error) {
      console.log(error);
    }
  }

  esDocumentoValido(): boolean {
    var regExp = this.data.IdentificadoPor == "DNI" ? new RegExp('^[1-9]{1}[0-9]{6,7}$') : new RegExp('^(?:20|27|23|24|30|33|34)[1-9]{1}[0-9]{7,8}$');
    return this.data.Documento && this.data.IdentificadoPor ? regExp.test(this.data.Documento.toString()) : true;
  }

  // Analizamos si clickeo CUIT/DNI y seteamos el regex
  setDocPattern() {
    let doc = this.forma.get("Documento");
    if (this.data.IdentificadoPor == "DNI")
      doc.setValidators([Validators.required, Validators.pattern('^[1-9]{1}[0-9]{6,7}$')]);
    else
      doc.setValidators([Validators.required, Validators.pattern('^(?:20|27|23|24|30|33|34)[0-9]{8,9}$')]);

    doc.updateValueAndValidity();
  }

  onUpdateCantidad(e) { }
  onFinishCantidad(e) { }
  onUpdateCuotas(e) { }
  onFinishCuotas(e) { }

  onChangeCantidad(e) {
    this.CPText = e.from;
    this.data.Prestamo.CantidadPedida = this.CPText;
  }

  onChangeCuotas(e) {
    this.CCText = e.from;
    this.data.Prestamo.CantidadDeCuotas = this.CCText;
  }
}

