import { AuthService } from './../../../auth/auth.service';
import { DataService } from './../../../data.service';
import { AppComponent } from './../../../app.component';
import { Solicitante } from './../../../Classes/app-models';
import { MatButtonModule } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component } from '@angular/core';
import { Http, Response, HttpModule, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FullDialogComponent } from '../../../PageComponents/full-dialog/full-dialog.component';
import { CommonConstants } from './../../../../app/shared/commonconstants';
import { Location } from '@angular/common';
@Component({
  selector: 'validacion',
  templateUrl: './validacion.html',
})
export class ValidacionComponent {
  CodigoSMS: string;
  showBar: boolean = true;
  disabled: boolean = false;

  constructor(private http: Http, private as: AuthService, private appComponent: AppComponent, private dataService: DataService, private router: Router, private snackBar: MatSnackBar, private dialog: MatDialog) {
    console.log("validacion");
    console.log(this.data);
    this.showBar = false;

    if (!this.data) { this.router.navigate(['/evaluar-solicitud']) }
  }

  get data(): Solicitante {
    return this.dataService.solicitanteData;
  }
  set data(value: Solicitante) {
    this.dataService.solicitanteData = value;
  }

  checkLength(): boolean {
    if (this.CodigoSMS && this.CodigoSMS.length == 5) { return false } else { return true }
  }

  validarSMS() {
    this.showBar = true;
    this.disabled = true;

    let response: any = {};

    let tempObj: any = {};
    tempObj.CodigoSMS = this.CodigoSMS;
    let body = JSON.stringify({ CodigoSMS: this.CodigoSMS });
    let authorization = 'bearer ' + this.appComponent.Jwt;
    let options = new RequestOptions({ headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': authorization }) });
    let url = CommonConstants.WebAPIEndpoints.ValidarYCrear;

    console.log(body);
    console.log(this.appComponent.Jwt);

    let req = this.http.post(url,
      body, options)
      .subscribe(
        (res: any) => {
          this.appComponent.Jwt = response.Jwt;
          //this.login();

          this.data.PrestamoOk = true;
          this.router.navigate(['/prestamo-otorgado']);
        },
        (err: any) => {
          this.showBar = false;
          this.disabled = false;

          // Código falla
          if (err.status === 401) {

            let dialogRef = this.dialog.open(FullDialogComponent, {
              data: { title: 'Error en validación', mensaje: 'Código de celular incorrecto. Por favor inténtelo de nuevo.' },
            });

            dialogRef.afterClosed().subscribe(result => {
              console.log(`Dialog closed: ${result}`);
            });

          }
          console.log('Error occured');
        }, () => this.showBar = false
      );
  }

  login() {
    this.data.PrestamoOk = true;

    if (!this.as.isAuthenticated()) {
      this.as.Lock.show();
      this.as.Lock.on("authenticated", this.as.Lock.setSession);
    } else {
      this.router.navigate(['']);
    }
  }
}
