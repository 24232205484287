import { Component, OnInit, Injectable } from '@angular/core';
import { FullDialogComponent } from '../../PageComponents/full-dialog/full-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.css']
})
@Injectable()
export class PlaygroundComponent implements OnInit {


  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    let dialogRef = this.dialog.open(FullDialogComponent, {
      width: '600px',
      data: 'Errorrrrr'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog closed: ${result}`);
    });
  }

}
