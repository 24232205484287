import { TopBarComponent } from './PageComponents/top-bar/top-bar.component';
import { FooterComponent } from './PageComponents/footer/footer.component';
import { AuthService } from './auth/auth.service';
import { ValidacionComponent } from './pages/comenzar-validacion/validacion/validacion';
import { ComenzarValidacionComponent } from './pages/comenzar-validacion/comenzar-validacion.component';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { DataService } from './data.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule, MatSnackBarModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatMenuModule, MatCheckboxModule, MatIconModule, MatFormFieldModule, MatSliderModule, MatInputModule, MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DATE_LOCALE, MatGridListModule, MatProgressBarModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import 'hammerjs';
/* Modulos */
import { RouterModule, Routes } from '@angular/router';
/* Angular Material */
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { HomeComponent } from './pages/home/home.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './Classes/date-adapter';
import { FullDialogComponent } from './PageComponents/full-dialog/full-dialog.component';
import { PlaygroundComponent } from './pages/playground/playground.component';
import { SoundManagerComponent } from './PageComponents/sound-manager/sound-manager.component';
import { TermsComponent } from './pages/terms/terms.component';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeARG from '@angular/common/locales/es-AR';
registerLocaleData(localeARG);

import { EvaluarSolicitudComponent } from './pages/evaluar-solicitud/evaluar-solicitud.component';
import { EvaluarSolicitudOkComponent } from './pages/evaluar-solicitud/evaluar-solicitud-ok/evaluar-solicitud-ok.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { RenunciaComponent } from './pages/renuncia/renuncia.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';
import { ConocenosComponent } from './pages/conocenos/conocenos.component';
import { DatosPersonalesComponent } from './pages/evaluar-solicitud/datos-personales/datos-personales.component';
import { PoliticasYPrivacidadComponent } from './pages/politicas-y-privacidad/politicas-y-privacidad.component';
import { PrestamoOtorgadoComponent } from './PageComponents/prestamo-otorgado/prestamo-otorgado.component';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TermsComponent,
    FullDialogComponent,
    PlaygroundComponent,
    FullDialogComponent,
    SoundManagerComponent,
    TermsComponent,
    EvaluarSolicitudComponent,
    EvaluarSolicitudOkComponent,
    ComenzarValidacionComponent,
    ValidacionComponent,
    ContactoComponent,
    RenunciaComponent,
    PreguntasFrecuentesComponent,
    FooterComponent,
    TopBarComponent,
    ConocenosComponent,
    DatosPersonalesComponent,
    PoliticasYPrivacidadComponent,
    PrestamoOtorgadoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    ReCaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTableModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    IonRangeSliderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDYv5zA_zdnIXvjsTgkFgsuntprJhwzhkU'
    })
  ],
  entryComponents: [FullDialogComponent],
  providers: [
    DataService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    SoundManagerComponent,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  /* function sacarLlaves (string data){
     return replace()
   }*/
}
