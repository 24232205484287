import { Router } from '@angular/router';
import { DataService } from './../../data.service';
import { Solicitante } from './../../Classes/app-models';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prestamo-otorgado',
  templateUrl: './prestamo-otorgado.component.html',
  styleUrls: ['./prestamo-otorgado.component.css']
})
export class PrestamoOtorgadoComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit() {

     if(!this.data || !this.data.PrestamoOk) {
       console.log("ASD");
       this.router.navigate(['/']);
     } else {
       this.data.PrestamoOk = false;
    }
  }

  get data(): Solicitante {
    return this.dataService.solicitanteData;
  }
  set data(value: Solicitante) {
    this.dataService.solicitanteData = value;
  }
}
