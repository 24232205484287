import { MatDialog } from '@angular/material';
import { FullDialogComponent } from '../../PageComponents/full-dialog/full-dialog.component';
import { CommonConstants } from '../../shared/commonconstants';
import { Http, RequestOptions, Headers } from '@angular/http';
import { DataService } from '../../data.service';
import { Solicitante } from '../../Classes/app-models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component } from '@angular/core';

export class IRenuncia {
  Nombre: string;
  DNI: string;
  CUIT: string;
  Celular: string;
  Motivo: string;
  Mensaje: string;
}

@Component({
  selector: 'app-renuncia',
  templateUrl: './renuncia.component.html',
  styleUrls: ['./renuncia.component.css']
})
export class RenunciaComponent {

  renuncia: FormGroup;
  showBar: Boolean = true;

  model: IRenuncia = new IRenuncia();


  constructor(public dataService: DataService, private http: Http, private dialog: MatDialog) {

    this.showBar = false;

    this.renuncia = new FormGroup({
      'DNI': new FormControl('', [Validators.required]),
      'CUIT': new FormControl('', [Validators.required]),
      'Nombre': new FormControl('', [Validators.required]),
      'Celular': new FormControl('', [Validators.required]),
      'Motivo': new FormControl('', [Validators.required]),
      'Mensaje': new FormControl('', [Validators.required])
    })
  }


  enviarMail() {

    if (this.renuncia.valid) {
      this.showBar = true;
      // Hacer algo

      let body = JSON.stringify(this.model);
      let options = new RequestOptions({ headers: new Headers({ 'Content-Type': 'application/json' }) });
      let url = CommonConstants.WebAPIEndpoints.Contactanos;

      console.log(body);

      let req = this.http.post(url,
        body, options)
        .subscribe(
          (res: any) => {
            this.showMessage("Notificación", "Su mensaje ha sido enviado correctamente");
          },
          err => {
            this.showMessage("Notificación", "Hubo un error, por favor intente más tarde");
          }, () => this.showBar = false
        );
    }
  }

  showMessage(title: string, mensaje: string) {
    let dialogRef = this.dialog.open(FullDialogComponent, {
      data: { title: title, mensaje: mensaje },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
