import { Injectable } from '@angular/core';


@Injectable()
class WebAPIEndpointsClass {
  constructor() {
  }
  // URL Base
  public readonly BaseEndpoint: string = "https://tinuvielapi.azurewebsites.net/api/";

  public get EvaluarSolicitud() { return this.BaseEndpoint + "EvaluarSolicitud"; }
  public get ComenzarValidacion() { return this.BaseEndpoint + "ComenzarValidacion"; }
  public get ValidarYCrear() { return this.BaseEndpoint + "ValidarYCrear"; }
  public get Contactanos() { return this.BaseEndpoint + "Contactanos"; }
}

export class CommonConstants {
  constructor() {

  }
  /// Lazy way to put all the stuff in a namespace-like structure
  public static WebAPIEndpoints = new WebAPIEndpointsClass();
}
