import { AuthService } from './../../auth/auth.service';
import { Component } from '@angular/core';
import 'rxjs/add/observable/interval';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent  {

  constructor(public as: AuthService) {}

}
