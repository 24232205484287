import { CommonConstants } from './../../shared/commonconstants';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DataService } from './../../data.service';
import { FormGroup } from '@angular/forms';
import { Solicitante } from './../../Classes/app-models';
import { AppComponent } from './../../app.component';
import { Component } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { FormControl, Validators } from '@angular/forms';
import { FullDialogComponent } from '../../PageComponents/full-dialog/full-dialog.component';

@Component({
  selector: 'app-comenzar-validacion',
  templateUrl: './comenzar-validacion.component.html',
  styleUrls: ['./comenzar-validacion.component.css']
})
export class ComenzarValidacionComponent {

  response: any = {};
  //solicitante: Solicitante = new Solicitante;
  showBar: boolean = true;
  disabled: boolean = false;
  forma: FormGroup;
  maxDate: Date;

  constructor(private http: Http, public appComponent: AppComponent, public dataService: DataService, private router: Router, private dialog: MatDialog) {
    console.log("EVALUAR-SOLICITUD");

     if (!this.data) { this.router.navigate(['/evaluar-solicitud']) }
     else {

      this.showBar = false;

      // this.data.CodigoArea = "11";
      // this.data.Celular = "50948908";
      // this.data.CBU = "0720731288000035451004";
      // this.data.Nombre = "Franco";
      // this.data.Email = "franco.cifuentes@test.com.ar";
      // this.data.CP = "1640";
      // this.data.Direccion = "Juncal 194";
      // this.data.FechaUltimoCobro = new Date(1992, 4, 10, 12, 0, 0, 0);
      // this.data.FechaUltimoCobro = new Date();

      console.log(this.data);

      this.forma = new FormGroup({
        CBU: new FormControl('', [Validators.required, Validators.pattern('[0-9]{22}')]),
        Nombre: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z\\s]+")]),
        CP: new FormControl('', [Validators.required]),
        Direccion: new FormControl('', [Validators.required]),
        FechaUltimoCobro: new FormControl('', [Validators.required])
      })
      this.maxDate = new Date();
    }
  }

  get data(): Solicitante {
    return this.dataService.solicitanteData;
  }
  set data(value: Solicitante) {
    this.dataService.solicitanteData = value;
  }

  public crearPrestamo() {
    console.log("Es valido: ", this.forma.valid);
    if (this.forma.valid) {
      this.showBar = true;
      this.disabled = true;

      console.log(this.data);

      let body = JSON.stringify(this.data.getDataForCrearPrestamo());

      let authorization = 'bearer ' + this.appComponent.Jwt;
      let options = new RequestOptions({ headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': authorization }) });
      let url = CommonConstants.WebAPIEndpoints.ComenzarValidacion;

      console.log(body);
      console.log(authorization);

      let req = this.http.post(url,
        body, options)
        .subscribe(
          (res: any) => {
            console.log(JSON.parse(res._body));
            this.response = JSON.parse(res._body);
            this.appComponent.Jwt = this.response.Jwt;
            this.data.PrefijoSMS = this.response.PrefijoSMS;

            this.router.navigate(['/validacion']);
          },
          (err: any) => {
            console.log('Error occured');
            this.showBar = false;
            this.disabled = false;
            if (err.status === 401) {

              this.dialog.open(FullDialogComponent,
                {
                  data: JSON.parse(err._body)
                });
            }
          }, () => this.showBar = false
        );
    }
  }
}
