import { PoliticasYPrivacidadComponent } from './pages/politicas-y-privacidad/politicas-y-privacidad.component';
import { DatosPersonalesComponent } from './pages/evaluar-solicitud/datos-personales/datos-personales.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { RenunciaComponent } from './pages/renuncia/renuncia.component';
import { ValidacionComponent } from './pages/comenzar-validacion/validacion/validacion';
import { ComenzarValidacionComponent } from './pages/comenzar-validacion/comenzar-validacion.component';
import { EvaluarSolicitudComponent } from './pages/evaluar-solicitud/evaluar-solicitud.component';
import { EvaluarSolicitudOkComponent } from './pages/evaluar-solicitud/evaluar-solicitud-ok/evaluar-solicitud-ok.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PlaygroundComponent } from './pages/playground/playground.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ConocenosComponent } from './pages/conocenos/conocenos.component';
import { PrestamoOtorgadoComponent } from './PageComponents/prestamo-otorgado/prestamo-otorgado.component';

const routes = [
  { path: '', component: HomeComponent, data: { state: 'home' } },
  { path: 'playground79', component: PlaygroundComponent },
  { path: 'evaluar-solicitud', component: EvaluarSolicitudComponent, data: { state: 'evaluar-solicitud' } },
  { path: 'contacto', component: ContactoComponent, data: { state: 'contacto' } },
  { path: 'renuncia', component: RenunciaComponent, data: { state: 'renuncia' } },
  { path: 'evaluar-solicitud-ok', component: EvaluarSolicitudOkComponent, data: { state: 'evaluar-solicitud-ok' } },
  { path: 'comenzar-validacion', component: ComenzarValidacionComponent, data: { state: 'comenzar-validacion' } },
  { path: 'validacion', component: ValidacionComponent, data: { state: 'validacion' } },
  { path: 'terms', component: TermsComponent, data: { state: 'terms' } },
  { path: 'conocenos', component: ConocenosComponent, data: { state: 'conocenos' } },
  { path: 'preguntas-frecuentes', component: PreguntasFrecuentesComponent, data: { state: 'preguntas-frecuentes' } },
  { path: 'datos-personales', component: DatosPersonalesComponent, data: { state: 'datos-personales' } },
  { path: 'politicas-de-privacidad', component: PoliticasYPrivacidadComponent, data: { state: 'politicas-de-privacidad' } },
  { path: 'prestamo-otorgado', component: PrestamoOtorgadoComponent, data: { state: 'prestamo-otorgado' } },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
