import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
declare var Auth0Lock: any;

@Injectable()
export class AuthService {

  public Lock: any;

  constructor(public router: Router) {

    const options = {

      auth: {
        sso: false
      },

      autoclose: true,
      language: 'es',
      //container: 'login',

      theme: {
        primaryColor: '#3b025f',
        logo: '../assets/img/logo-sitio.png'
      },

      languageDictionary: {
        emailInputPlaceholder: "email@dominio.com",
        title: "Empiece aquí!",
        forgotPasswordAction: "¿Olvidaste tu contraseña?",
        allowShowPassword: true,
      },

    };

    this.Lock = new Auth0Lock('vO1LW76mPTwNeYfuGHJYpE7E-YrcOu3O', 'kromas.auth0.com', options);
    this.Lock.on("authenticated", this.setSession);
  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    console.log(authResult);
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  public getSessionToken(): string {

    if (this.isAuthenticated())
      return localStorage.getItem("access_token");

    return null;
  }

}
