import { query } from '@angular/animations';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataService } from './../../data.service';
import { Solicitante, Prestamo } from './../../Classes/app-models';
import { Component, Input, OnInit } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public CantidadCuotas: number;
  public CantidadPlata: number;
  public ValorCuota: number;
  public CPText: any;
  public CCText: any;
  public CP: number;
  public CC: number;

  constructor(private auth: AuthService, public dataService: DataService, public router: Router, public activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    console.log(this.auth.getSessionToken());

    if (!this.data) {
      console.log("NO HABIA DATA");
      this.data = new Solicitante();
      //this.data.Prestamo = new Prestamo();
    }

    this.CPText = this.CP = this.data.Prestamo.CantidadPedida;
    this.CCText = this.CC = this.data.Prestamo.CantidadDeCuotas;
    console.log(this.data);
  }

  set data(value: Solicitante) {
    this.dataService.solicitanteData = value;
  }

  get data(): Solicitante {
    return this.dataService.solicitanteData;
  }

  onUpdateCantidad(e) { }
  onFinishCantidad(e) { }
  onUpdateCuotas(e) { }
  onFinishCuotas(e) { }

  onChangeCantidad(e) {
    //this.data.Prestamo.CantidadPedida = e.from;
    this.CPText = e.from;
  }

  onChangeCuotas(e) {
    //this.data.Prestamo.CantidadDeCuotas = e.from;
    this.CCText = e.from;
  }

  redirectEvalSol() {
    this.data.Prestamo.CantidadDeCuotas = this.CCText;
    this.data.Prestamo.CantidadPedida = this.CPText;
    console.log(this.data.Prestamo.CantidadDeCuotas);
    console.log(this.data.Prestamo.CantidadAPagar);

    this.router.navigate(['/evaluar-solicitud']);
  }

  PMT(np, pv) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var ir, pmt, pvif, fv, type;
    /*29-03-2022 se cambio la tasa a pedido de Luciano Sejas
    /* if (np > 6) { ir = 0.1936 } else { ir = 0.2178 };*/
    if (np > 6) { ir = 0.1600 } else { ir = 0.1600 };
    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
      return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * pv * (pvif + fv) / (pvif - 1);

    if (type === 1)
      pmt /= (1 + ir);

    pmt = pmt * -1;

    return pmt;
  }

  // reset() {
  //   this.data.PrestamoOk = false;
  // }
}
