import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.css']
})
export class PreguntasFrecuentesComponent implements OnInit {

  public FaqList1: any = [
    {
      Title: '¿Cuáles son los requisitos para pedir un préstamo?', opened: false, Description: `- Que seas mayor de 18 años.<br>
        - Que tengas algún tipo de ingreso formal.<br>
        - Residir en Argentina.<br>
        - Que tengas una cuenta bancaria a tu nombre que no corresponda a un plan social.`},
    {
      Title: '¿Cuánto dinero puedo solicitar y en cuántas cuotas?', opened: false, Description: `Podes solicitar hasta $30.000 y devolverlo desde 1 a 12 cuotas.`
    },
    {
      Title: '¿Cómo y cuándo recibo el dinero?', opened: false, Description: `Una vez realizada la solicitud de crédito, en caso de ser aprobada, los fondos se transfieren en 48
      hs a tu cuenta bancaría, en donde luego se debitará la cuota mensualmente.`
    },
    {
      Title: 'Me olvidé el valor de mis cuotas y las fechas de pago, ¿cómo puedo averiguarlo?', opened: false, Description: `Podés comunicarte al 0810-345-2033 int 2. De lunes a viernes de 9:30 a 17:30 hs.`
    },
    {
      Title: '¿Por qué no me hicieron una oferta en el momento?', opened: false, Description: `Si no tuviste una oferta, es dado a que no podemos ofrecer un préstamo que se ajuste a tu perfil.`
    },
    {
      Title: '¿Cómo los contacto?', opened: false, Description: `Podes contactarnos por el medio que más te convenga:`
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  ShowFaq(arrayName, index) {
    this.FaqList1.map(function (x) {
      x.opened = false;
      return x
    });
    if (arrayName == 'FaqList1') {
      this.FaqList1[index].opened = true;
    }
  }
}
