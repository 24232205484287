export class Solicitante {

  constructor() {
    this.Identificacion = {};
    this.CondicionesDePrestamo = {};
    this.Prestamo = new Prestamo();
  }

  // variables del cliente - primer paso - evaluar-solicitud
  Documento: string;
  Ingreso: number;
  DescuentoDicho: number;
  Prestamo: Prestamo;
  Identificacion: any;
  CondicionesDePrestamo: any;

  IdentificadoPor: string;
  CaptchaToken: string;
  TipoClienteDicho: string;
  PrestamoOk: boolean = false;

  // variables del cliente - segundo paso - crear-prestamo
  Celular: string;
  CodigoArea: string;
  CBU: string;
  Email: string;
  Nombre: string;
  Sexo: string;
  CP: string;
  Direccion: string;
  // FechaNacimiento: Date;
  FechaUltimoCobro: Date;

  // variables del servidor

  PrefijoSMS: string;

  public getDataForCrearPrestamo(): any {
    const thisTypeCasted: Solicitante = this;
    const postObj = { ...thisTypeCasted };
    postObj.FechaUltimoCobro = this.dateToIsoDateStr(postObj.FechaUltimoCobro);
    postObj.Celular = postObj.CodigoArea.toString() + postObj.Celular.toString();
    return postObj;
  }

  /// Pa kromas
  public dateToIsoDateStr(isoDate: Date): any {
    isoDate.setUTCHours(0, 0, 0, 0);
    // Remove the time part cause we don't need it =)
    return isoDate.toISOString().substring(0, isoDate.toISOString().indexOf('T'));
  }

}

export class Prestamo {
  // variables del cliente
  CantidadPedida: number = 6000;
  CantidadDeCuotas: number = 12;

  // variables que vienen del servidor
  TasaDeInteres: number;
  CantidadAPagar: number;
  Cuota: number;
}
