import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Solicitante } from './../../../Classes/app-models';
import { DataService } from './../../../data.service';
import { AppComponent } from './../../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-evaluar-solicitud-ok',
  templateUrl: './evaluar-solicitud-ok.component.html',
  styleUrls: ['./evaluar-solicitud-ok.component.css']
})
export class EvaluarSolicitudOkComponent {

  readonly MAXIMO_PRESTAMO_TINUVIEL: number = 30000;

  aux: any = [];
  dataSource = new MatTableDataSource(this.aux);

  displayedColumns = ['cuota', 'mes', 'cantidad'];


  showBar: boolean = false;
  panelPrestamoPedido: boolean;
  forma: FormGroup;
  text: string;


  condicionesPrestamo: {
    CuotaMaxima: number;
    PrestamoMaximo: number;
    TasasDeInteres: Array<number>;
  }

  cuotasMin: number = 1;
  prestamoMax: number = 30000;
  //cuotasMax: number = 12;
  //cuotasMax: number = 12;
  cantidadADevolver: number;

  constructor(private appComponent: AppComponent, private router: Router, public dataService: DataService) {
    console.log("EVALUAR-SOLICITUD-OK");

    // Evitar leer esto
    if (!this.data) { this.router.navigate(['/evaluar-solicitud']) }
    else {


      //this.data.CondicionesDePrestamo.CuotaMaxima = 5635;
      //this.data.CondicionesDePrestamo.PrestamoMaximo = 25555;
      //this.data.CondicionesDePrestamo.TasasDeInteres = [0.2178, 0.2178, 0.2178, 0.2178, 0.2178, 0.2178, 0.1936, 0.1936, 0.1936, 0.1936, 0.1936, 0.1936];

      console.log(this.data);
      console.log(this.data.Prestamo.CantidadPedida);

      this.condicionesPrestamo = this.data.CondicionesDePrestamo;

      this.calPrestamoMax();
      if (this.data.Prestamo.CantidadPedida >= this.prestamoMax) { this.data.Prestamo.CantidadPedida = this.prestamoMax }
      this.calcCuotasMin();
      this.listarCuotas();

    }

    this.forma = new FormGroup({
      CantidadPedida: new FormControl('', [Validators.required]),
      CantidadDeCuotas: new FormControl('', [Validators.required]),
    })
  }

  get data(): Solicitante {
    return this.dataService.solicitanteData;
  }
  set data(value: Solicitante) {
    this.dataService.solicitanteData = value;
  }

  // Evaluo si la persona eligio el prestamo sugerido y lo seteo como prestamo
  public aceptarPrestamo() {
    if (this.forma.valid) {
      this.showBar = true;
      this.router.navigate(['/comenzar-validacion']);
    }
  }

  listarCuotas() {
    this.aux = [];
    this.dataSource = new MatTableDataSource(this.aux);

    // Debemos llenar las tablas con las cuotas que tienen que venir del servidor
    for (var i = 1; i < this.data.Prestamo.CantidadDeCuotas + 1; i++) {
      var d = new Date();
      var currentRow: any = {};
      currentRow.numero = i;
      d.setMonth(d.getMonth() + i);
      currentRow.mes = d.getUTCDate() + '/' + (d.getUTCMonth() + 1) + '/' + d.getUTCFullYear().toString().substring(2, 4);
      currentRow.monto = this.PMT(this.data.Prestamo.CantidadDeCuotas, this.data.Prestamo.CantidadPedida, this.condicionesPrestamo.TasasDeInteres);
      this.aux.push(currentRow);
    }
  }

  onChangeCantidad(e) {
    this.data.Prestamo.CantidadPedida = e.value;
    this.calcCuotasMin();
    this.listarCuotas();
  }

  onChangeCuotas(e) {
    this.data.Prestamo.CantidadDeCuotas = e.value;
    this.calPrestamoMax();
    this.listarCuotas();
  }

  calcCuotasMin() {
    this.cantidadADevolver = this.PMT(this.data.Prestamo.CantidadDeCuotas, this.data.Prestamo.CantidadPedida, this.condicionesPrestamo.TasasDeInteres) * this.data.Prestamo.CantidadDeCuotas;
    this.cuotasMin = Math.ceil(this.cantidadADevolver / this.condicionesPrestamo.CuotaMaxima);
    console.log(`Cantidad de cuotas ${this.data.Prestamo.CantidadDeCuotas} Cantidad pedida: ${this.data.Prestamo.CantidadPedida} Cant devolver: ${this.cantidadADevolver} CuotasMin: ${this.cuotasMin}`);
  }

  calPrestamoMax() {
    this.prestamoMax = this.topeMax(this.condicionesPrestamo.TasasDeInteres, this.data.Prestamo.CantidadDeCuotas, this.condicionesPrestamo.CuotaMaxima);
  }

  topeMax(irList, cuotas, cuotaMax) {
    for (let i = 100; i < this.MAXIMO_PRESTAMO_TINUVIEL; i += 100) {
      let cuota = this.PMT(cuotas, i, irList);
      if (cuota > cuotaMax) {
        return i - 100;
      }
    }

    return this.MAXIMO_PRESTAMO_TINUVIEL;
  }

  PMT(cp, cant, irList) {
    /*
     * irList   - interest rate per month
     * cp   - cuotas
     * cant - cantidad pedida
     * fv   - future value
     */
    var ir, pmt, pvif, fv, type;

    ir = irList[cp - 1];

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
      return -(cant + fv) / cp;

    pvif = Math.pow(1 + ir, cp);
    pmt = ir * cant * (pvif + fv) / (pvif - 1);

    if (type === 1)
      pmt /= (1 + ir);

    this.data.Prestamo.TasaDeInteres = ir;
    return pmt;
  }
}

