import { Component, OnInit, Injectable } from '@angular/core';

@Component({
  selector: 'app-sound-manager',
  templateUrl: './sound-manager.component.html',
  styleUrls: ['./sound-manager.component.css']
})
@Injectable()
export class SoundManagerComponent implements OnInit {

  private alert: any;

  constructor() {
    this.alert = new Audio("/assets/sounds/alert.mp3");
    this.alert.load();
  }

  public playAlert() {
    this.alert.play();
  }

  ngOnInit() {
  }

}
