import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { SoundManagerComponent } from '../sound-manager/sound-manager.component';

@Component({
  selector: 'app-full-dialog',
  templateUrl: './full-dialog.component.html',
  styleUrls: ['./full-dialog.component.css']
})
export class FullDialogComponent implements OnInit {

  constructor(public thisDialogRef: MatDialogRef<FullDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private soundManager: SoundManagerComponent) { }

  ngOnInit() {
    this.soundManager.playAlert();
  }

  onCloseConfirm() {
    this.thisDialogRef.close('Confirm');
  }

  onCloseCancel() {
    this.thisDialogRef.close('Cancel');
  }

}
