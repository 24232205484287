import { fadeAnimation } from './PageComponents/animations/fade.animation';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation],
  encapsulation: ViewEncapsulation.None
})


export class AppComponent {

  title = 'TinuvielSA';
  data = '';
  Jwt = 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1MzgwMjEzODIsImlzcyI6IkFjdGlvbkZpbnRlY2giLCJhdWQiOiJFdmVyeW9uZSIsIlBlcnNvbmEiOiJ7XCJJZGVudGlmaWNhZG9Qb3JcIjowLFwiQ2VsdWxhclwiOm51bGwsXCJFbWFpbFwiOm51bGwsXCJEb2N1bWVudG9cIjpcIjM2NjQ0NDg5XCIsXCJOb21icmVcIjpudWxsLFwiU2V4b1wiOjAsXCJGZWNoYU5hY2ltaWVudG9cIjpudWxsLFwiQ0JVXCI6bnVsbCxcIlByZXN0YW1vc1wiOntcIlBlZGlkb1wiOntcIlBlZGlkb0luaWNpYWxcIjoyMDAwLFwiQ2FudGlkYWREZUN1b3Rhc1wiOjEyLFwiVGFzYURlSW50ZXJlc0FudWFsXCI6MC4zNSxcIkNhbnRpZGFkQVBhZ2FyXCI6MjcwMC4wLFwiQ3VvdGFcIjoyMjUuMH0sXCJTdWdlcmlkb1wiOntcIlBlZGlkb0luaWNpYWxcIjoyMzAwMCxcIkNhbnRpZGFkRGVDdW90YXNcIjoxMixcIlRhc2FEZUludGVyZXNBbnVhbFwiOjAuMzUsXCJDYW50aWRhZEFQYWdhclwiOjMxMDUwLjAsXCJDdW90YVwiOjI1ODcuNX19LFwiSW5ncmVzb1wiOjIzMDAwLjB9IiwiQ29uc3VsdGFOb3NpcyI6bnVsbH0.TBrsBC2ruCjz805juJO5r9R14V9fGQRGkcLtxkHr-JdHbAigLaFDcFWXHQ_IBlrElcljbJFZ9PxFdTQ6eUf8wQ';

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
  onActivate(event) {
    try { 
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } catch (e) {
      window.scrollTo(0, 0);
    }
  }
}
