import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicas-y-privacidad',
  templateUrl: './politicas-y-privacidad.component.html',
  styleUrls: ['./politicas-y-privacidad.component.css']
})
export class PoliticasYPrivacidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
