import { DataService } from './../../../data.service';
import { Solicitante } from './../../../Classes/app-models';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.css']
})
export class DatosPersonalesComponent implements OnInit {

  formaContacto: FormGroup;

  constructor(private router: Router, private dataService: DataService) { }

  ngOnInit() {

    this.formaContacto = new FormGroup({
      Email: new FormControl('', [Validators.required, Validators.email]),
      CodigoArea: new FormControl('', [Validators.required]),
      Celular: new FormControl('', [Validators.required])
    })

  }

  get data(): Solicitante {
    if (!this.dataService.solicitanteData) {
      this.dataService.solicitanteData = new Solicitante();
    }
    return this.dataService.solicitanteData;
  }

  verPrestamo() {
    if (this.formaContacto.valid) {
      this.router.navigate(['/evaluar-solicitud-ok']);
    }
  }

}
